import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { Markdown } from '@components/StrapiComponents';
import ShareIcon from '@sections/TrainingTitle/assets/share';
import { PATHS } from '@global/constants/urls';
import { MaterialTitleProps } from './materialTitle.types';
import {
  Author,
  MaterialInfo,
  SeparateLine,
  SubtitleContainer,
  TitleContainer,
  PublishedAt,
  Share,
  MaterialInfoWrapper,
  TagsContainer,
  Tag,
  ShareTooltip,
} from './materialTitle.styles';

const MaterialTitle = ({
  title,
  subtitle,
  author,
  publishedAt,
  roles,
  topics,
  careers,
}: MaterialTitleProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setRenderTooltip(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);

  return (
    <GlobalWrapper>
      <TitleContainer>
        <Markdown data={{ content: title }} />
        <MaterialInfo>
          <MaterialInfoWrapper>
            <PublishedAt>{publishedAt}</PublishedAt>
            <SeparateLine>|</SeparateLine>
            <Author href="#authorSection">{author}</Author>
          </MaterialInfoWrapper>
          <MaterialInfoWrapper>
            <Share onClick={handleCopyToClipboard}>
              <ShareIcon />
              <ShareTooltip renderTooltip={renderTooltip}>Copied Link!</ShareTooltip>
            </Share>
          </MaterialInfoWrapper>
        </MaterialInfo>
      </TitleContainer>
      {(careers.data.length > 0 || topics.data.length > 0 || roles.data.length > 0) && (
        <TagsContainer>
          {topics.data.map((topic) => (
            <Tag href={`${PATHS.MATERIAL}?topic=${topic.attributes.Topic}`}>
              {topic.attributes.Topic}
            </Tag>
          ))}
          {roles.data.map((role) => (
            <Tag href={`${PATHS.MATERIAL}?role=${role.attributes.Role}`}>
              {role.attributes.Role}
            </Tag>
          ))}
          {careers.data.map((career) => (
            <Tag href={`${PATHS.MATERIAL}?career=${career.attributes.Career_Cluster}`}>
              {career.attributes.Career_Cluster}
            </Tag>
          ))}
        </TagsContainer>
      )}
      <SubtitleContainer>
        <Markdown data={{ content: subtitle }} />
      </SubtitleContainer>
    </GlobalWrapper>
  );
};

export default MaterialTitle;
